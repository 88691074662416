
.login {
  background: #f6f6f6;
  display: flex;
  flex-flow: column;
  align-items: center;
  height: 100vh;
  position: relative;
  .name {
    width: 7.5rem;
    height: 0.88rem;
    background: #fff;
    text-align: center;
    font-size: 0.36rem;
    line-height: 0.88rem;
    .logo {
      width: 3.78rem;
      height: 0.98rem;
    }
  }

  // 操作
  .operation {
    margin-bottom: 0.24rem;

    .phone,
    .code {
      width: 7.5rem;
      height: 1.08rem;
      background: #ffffff;
      margin-top: 0.28rem;
      line-height: 1.08rem;
      position: relative;
      .code-value,
      .phone-value {
        ::v-deep .van-field__label {
          padding-left: 0.48rem;
          padding-top: 0.12rem;
        }
        ::v-deep .van-field__body {
          height: 0.8rem;
          .van-field__control {
            font-size: 0.32rem;
            height: 0.8rem;
            line-height: 0.8rem;
            padding-left: 0.48rem;
          }
        }
        ::v-deep .van-field__error-message {
          font-size: 0.23rem;
          padding-left: 0.48rem;
          // margin-top: 0.1rem;
        }
      }
      .img {
        width: 0.32rem;
        height: 0.32rem;
        position: absolute;
        left: 0.42rem;
        bottom: 0.38rem;
        z-index: 10;
      }
    }

    .code {
      .occupying {
        display: none;
      }
    }
  }

  // 登录按钮
  .submit-login {
    border: 0;
    color: #ffffff;
    width: 5.9rem;
    height: 0.8rem;
    background: #2cce98;
    border-radius: 0.4rem;
    position: absolute;
    top: 50%;
    &::before {
      display: none;
    }
  }
}
