
.device-manage {
  background-color: #f5f5f5;
  display: flex;
  flex-flow: column;
  align-items: center;
  height: 100vh;

  .fuzzy-query {
    padding: 0.18rem 0.3rem;
    background-color: #fff;
    margin-bottom: 0.24rem;
    .title {
      width: 1.44rem;
      height: 0.5rem;
      font-size: 0.36rem;
      font-family: PingFang-SC-Bold, PingFang-SC;
      font-weight: bold;
      color: #333333;
      line-height: 0.5rem;
      margin: 0.22rem auto;
    }

    .btn {
      width: 2.18rem;
      height: 0.62rem;
      background: #2cce98;
      border-radius: 0.08rem;
      font-size: 0.28rem;
      font-weight: 400;
      color: #ffffff;
      margin-bottom: 0.16rem;
    }
    .type-selection {
      width: 6.9rem;
      height: 0.62rem;
      border-radius: 0.08rem;
      border: 0.02rem solid #2cce98;
      color: #2cce98;
      position: relative;
      margin-bottom: 0.16rem;

      &::after {
        content: " ";
        display: block;
        width: 0.28rem;
        height: 0.28rem;
        // background-image: url("./image/arrowhead.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        position: absolute;
        right: 0.3rem;
        top: 50%;
        transform: translateY(-50%);
      }

      ::v-deep .van-dropdown-menu__bar {
        height: 100%;

        .van-dropdown-menu__item {
          .van-dropdown-menu__title {
            height: 100%;

            &::after {
              display: none;
            }

            .van-ellipsis {
              height: 100%;
              font-size: 0.28rem;
              font-weight: 400;
              line-height: 2;
            }
          }
        }
      }
    }

    .search {
      display: flex;
      justify-content: space-between;

      .conditional-value {
        padding: 0;
        background: #eeeeee;
        width: 4.6rem;

        ::v-deep .van-field__body {
          height: 0.6rem;

          .van-field__control {
            font-size: 0.32rem;
            height: 0.6rem;
            line-height: 0.6rem;
            padding-left: 0.48rem;
          }
        }

        ::v-deep .van-field__error-message {
          font-size: 0.23rem;
          padding-left: 0.48rem;
          margin-top: 0.1rem;
        }

        ::-webkit-input-placeholder {
          font-weight: 400;
          color: #999999;
          font-size: 0.32rem;
        }
      }

      .bt {
        width: 2.18rem;
        height: 0.62rem;
        background: #2cce98;
        border-radius: 0.08rem;
        font-size: 0.28rem;
        font-weight: 400;
        color: #ffffff;
      }
    }
  }

  .untreated-table {
    .header {
      display: flex;
      justify-content: space-between;

      .item {
        flex: 1;
        height: 0.88rem;
        background: #2cce98;
        line-height: 0.88rem;
        text-align: center;
        font-size: 0.28rem;
        font-weight: 400;
        color: #ffffff;
        margin-right: 0.1rem;
      }

      .operate {
        // width: 2.18rem;
        margin: 0;
      }
    }

    .table-container {
      .v-list {
        height: 8.5rem;
        overflow-y: auto;
      }

      .table-data {
        display: flex;
        justify-content: space-between;
        margin-top: 0.16rem;

        .item {
          flex: 1;
          word-break: break-word;
          min-height: 0.88rem;
          padding: 0.04rem 0.3rem;
          background-color: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 0.1rem;
          text-align: center;
        }

        .operate,
        .look {
          // width: 2.18rem;
          text-align: center;
          font-size: 0.28rem;
          font-weight: bold;
          color: #ce2c2c;
        }

        .operate {
          margin: 0;
        }
      }
    }
  }
  .unbind-popup {
    width: 5.3rem;
    height: 3.5rem;
    background: #ffffff;
    border-radius: 0.2rem;
    .title {
      padding-top: 0.28rem;
      width: 1.28rem;
      height: 0.44rem;
      font-size: 0.32rem;
      font-family: PingFang-SC-Bold, PingFang-SC;
      font-weight: bold;
      color: #333333;
      margin: 0 auto;
      line-height: 0.44rem;
    }
    .content {
      padding: 0.72rem;
    }
    .foot {
      display: flex;
      justify-content: space-evenly;
      .submit-notes {
        font-weight: bold;
        color: #2cce98;
      }
    }
  }
}
