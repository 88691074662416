
.add-device {
  position: relative;
  background: #f5f5f5;
  width: 7.5rem;
  height: 100vh;
  .operation {
    li {
      margin-top: 0.18rem;
    }
  }
  .van-nav-bar {
    ::v-deep .van-nav-bar__title {
      font-size: 0.36rem;
      font-family: PingFang-SC-Bold, PingFang-SC;
      font-weight: bold;
      color: #333333;
      line-height: 0.5rem;
    }
  }
  .submit {
    border: 0;
    color: #ffffff;
    width: 5.9rem;
    height: 0.8rem;
    background: #2cce98;
    border-radius: 0.4rem;
    position: absolute;
    bottom: 0;
    left: 11%;
  }
}
